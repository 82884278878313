<template>
  <form class="signup-form">
    <slot />
  </form>
</template>

<script>
export default {};
</script>

<style lang="sass">
.signup-form
  display: flex
  flex-wrap: wrap
  gap: 40px 80px

  @media screen and (max-width: 1199px)
    gap: 32px 44px

  @media screen and (max-width: 600px)
    gap: 24px

.signup-form__group-item
  flex: 0 0 calc((100% / 12 * 6) - 40px)
  display: flex
  flex-direction: column
  gap: 8px

  @media screen and (max-width: 1199px)
    flex: 0 0 calc((100% / 12 * 6) - 22px)

  @media screen and (max-width: 600px)
    flex: 0 0 100%

.signup-form__group-item .input,
.signup-form__group-item .v-autocomplete .v-input__slot
  @media screen and (max-width: 1199px)
    font-size: 16px!important

  @media screen and (max-width: 600px)
    height: 56px!important

.signup-form__group-item.checkbox
  margin-top: 5px

  @media screen and (max-width: 600px)
    margin-top: 12px

.signup-form__group-item .autocomplete-box .v-list-item__title
  @media screen and (max-width: 1199px)
    font-size: 16px!important

.signup-individual-form
  .signup-form__group-item.checkbox
    align-self: center
    margin-top: 28px

    @media screen and (max-width: 600px)
      margin-top: 14px

.signup-form__label
  font-size: 16px
  line-height: 19px
  color: var(--primary-color-dark-blue)
  opacity: 0.6
  pointer-events: none
</style>
