import {
  required,
  minLength,
  email,
  numeric,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

const fullName = helpers.regex(
  'alpha',
  /^([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])+\s(([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])(\s)?)+$/
);
const phone = helpers.regex('number', /^\+[0-9]+$/);

const isCheckedAgreement = (value) => !!value;

export default {
  validations: {
    userFullName: {
      required,
      alphaCheck: fullName,
    },
    userEmail: {
      required,
      email,
    },
    userPhone: {
      required,
      numeric: phone,
      minLength: minLength(11),
    },
    userPassword: {
      required,
      minLength: minLength(6),
    },
    repeatUserPassword: {
      required,
      sameAsPassword: sameAs('userPassword'),
    },
    agreement: {
      required: isCheckedAgreement,
    },
  },
  computed: {
    userFullNameErrorText() {
      if (!this.$v.userFullName.required) {
        return VueI18n.t('signup.individual.validation.user_fullname.empty');
      }
      if (!this.$v.userFullName.alphaCheck) {
        return VueI18n.t(
          'signup.individual.validation.user_fullname.alpha_check'
        );
      }
    },
    userEmailErrorText() {
      if (!this.$v.userEmail.required) {
        return VueI18n.t('signup.individual.validation.user_email.empty');
      }
      if (!this.$v.userEmail.email) {
        return VueI18n.t('signup.individual.validation.user_email.invalid');
      }
      if (this.serverError) {
        return VueI18n.t('signup.individual.validation.user_email.existing');
      }
    },
    userPasswordErrorText() {
      if (!this.$v.userPassword.required) {
        return VueI18n.t('signup.individual.validation.user_password.empty');
      }
      if (!this.$v.userPassword.minLength) {
        return VueI18n.t(
          'signup.individual.validation.user_password.min_length'
        );
      }
    },
    repeatUserPasswordErrorText() {
      if (!this.$v.repeatUserPassword.required) {
        return VueI18n.t('signup.individual.validation.user_password.empty');
      }
      if (!this.$v.repeatUserPassword.sameAsPassword) {
        return VueI18n.t(
          'signup.individual.validation.user_password.not_same_as'
        );
      }
    },
    userPhoneErrorText() {
      if (!this.$v.userPhone.required) {
        return VueI18n.t('signup.individual.validation.user_phone.empty');
      }
      if (!this.$v.userPhone.numeric) {
        return VueI18n.t('signup.individual.validation.user_phone.numeric');
      }
      if (!this.$v.userPhone.minLength) {
        return VueI18n.t('signup.individual.validation.user_phone.min_length');
      }
    },
    agreementErrorText() {
      if (!this.$v.agreement.required) {
        return VueI18n.t('signup.individual.validation.agreement.empty');
      }
    },
  },
};
