<template>
  <div class="signup-individual">
    <SignupOverlay>
      <Heading level="4" class="">
        {{ $t('signup.individual.title') }}
      </Heading>
      <div class="signup-individual-form">
        <SignupForm>
          <div class="signup-form__group-item">
            <label class="signup-form__label" for="name">
              {{ $t('signup.labels.individual.fullname') }}
            </label>
            <TextField
              id="name"
              v-model.trim="userFullName"
              name="name"
              required
              class="signup-form__input"
              type="text"
              :error="
                ($v.userFullName.$dirty && !$v.userFullName.required) ||
                ($v.userFullName.$dirty && $v.userFullName.$invalid)
              "
              :error-txt="userFullNameErrorText"
              @isFocus="clearFullNameField"
              @blur="$v.userFullName.$touch()"
              @input="inputFullNameHandler"
            />
          </div>
          <div class="signup-form__group-item">
            <label class="signup-form__label" for="email">
              {{ $t('signup.labels.individual.email') }}
            </label>
            <TextField
              id="email"
              v-model.trim="userEmail"
              type="email"
              name="email"
              required
              class="signup-form__input"
              :error="
                ($v.userEmail.$dirty && !$v.userEmail.required) ||
                ($v.userEmail.$dirty && $v.userEmail.$invalid) ||
                !!serverError
              "
              :error-txt="userEmailErrorText"
              @isFocus="clearEmailField"
              @blur="$v.userEmail.$touch()"
            />
          </div>
          <div class="signup-form__group-item">
            <label class="signup-form__label" for="password">
              {{ $t('signup.labels.individual.password') }}
            </label>
            <TextField
              id="password"
              v-model.trim="userPassword"
              :type="passwordFieldType"
              name="password"
              required
              :error="
                ($v.userPassword.$dirty && !$v.userPassword.required) ||
                ($v.userPassword.$dirty && $v.userPassword.$invalid)
              "
              :error-txt="userPasswordErrorText"
              is-exist-password-switcher
              :is-show-password="isShowPassword"
              @toggle-password="isShowPassword = !isShowPassword"
              @isFocus="clearPasswordField"
              @blur="$v.userPassword.$touch()"
            />
          </div>
          <div class="signup-form__group-item">
            <label class="signup-form__label" for="repeat-password">
              {{ $t('signup.labels.individual.repeat_password') }}
            </label>
            <TextField
              id="repeat-password"
              v-model.trim="repeatUserPassword"
              :type="repeatPasswordFieldType"
              name="repeat-password"
              required
              :error="
                ($v.repeatUserPassword.$dirty &&
                  !$v.repeatUserPassword.required) ||
                ($v.repeatUserPassword.$dirty && $v.repeatUserPassword.$invalid)
              "
              :error-txt="repeatUserPasswordErrorText"
              is-exist-password-switcher
              :is-show-password="isShowRepeatPassword"
              @toggle-password="isShowRepeatPassword = !isShowRepeatPassword"
              @isFocus="clearRepeatPasswordField"
              @blur="$v.repeatUserPassword.$touch()"
            />
          </div>
          <div class="signup-form__group-item">
            <label class="signup-form__label" for="phone">
              {{ $t('signup.labels.individual.phone') }}
            </label>
            <TextField
              id="phone"
              v-model.trim="userPhone"
              type="tel"
              name="phone"
              required
              placeholder="+ __ ___ ___ __ __"
              class="signup-form__input"
              :error="
                ($v.userPhone.$dirty && !$v.userPhone.required) ||
                ($v.userPhone.$dirty && $v.userPhone.$invalid)
              "
              :error-txt="userPhoneErrorText"
              @isFocus="clearPhoneField"
              @blur="$v.userPhone.$touch()"
            />
          </div>
          <div class="signup-form__group-item checkbox">
            <CheckboxField
              v-model="$v.agreement.$model"
              :error="$v.agreement.$dirty && !$v.agreement.required"
              :error-txt="agreementErrorText"
            />
          </div>
        </SignupForm>
      </div>
      <SignupControlButtons
        :back-button-text="$t('signup.button.back')"
        :go-button-text="$t('signup.button.registration')"
        :loading-go-btn="loadingSubmitBtn"
        @go-back="goBack"
        @go-on="submitHandler"
      />
    </SignupOverlay>
  </div>
</template>

<script>
import validate from '@/mixins/validation/signup/individualPerson';

import SignupOverlay from '@/components/Signup/SignupOverlay.vue';
import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import SignupForm from '@/components/Signup/SignupForm.vue';
import Heading from '@/elements/Heading/Heading.vue';
import TextField from '@/elements/Inputs/TextField.vue';
import CheckboxField from '@/elements/Inputs/CheckboxField.vue';

export default {
  components: {
    SignupOverlay,
    SignupControlButtons,
    SignupForm,
    Heading,
    TextField,
    CheckboxField,
  },
  mixins: [validate],
  data() {
    return {
      userFullName: '',
      userEmail: '',
      userPhone: '',
      userPassword: '',
      repeatUserPassword: '',
      isShowPassword: false,
      isShowRepeatPassword: false,
      agreement: false,
      loadingSubmitBtn: false,
      serverError: null,
    };
  },
  computed: {
    userFullNameArray() {
      return this.userFullName.split(' ');
    },
    userLastName() {
      return this.userFullNameArray[0];
    },
    userFirstName() {
      return this.userFullNameArray[1];
    },
    userSecondName() {
      return this.userFullNameArray[2];
    },
    passwordFieldType() {
      return this.isShowPassword ? 'text' : 'password';
    },
    repeatPasswordFieldType() {
      return this.isShowRepeatPassword ? 'text' : 'password';
    },
    userInfo() {
      const user = {
        last_name: this.userLastName,
        first_name: this.userFirstName,
        email: this.userEmail,
        telephone_number: this.userPhone,
        password: this.userPassword,
      };

      if (this.userSecondName) {
        user.second_name = this.userSecondName;
      }

      return user;
    },
  },
  methods: {
    clearFullNameField() {
      this.$v.userFullName.$reset();
    },
    clearEmailField() {
      this.$v.userEmail.$reset();
      this.serverError = null;
    },
    clearPasswordField() {
      this.$v.userPassword.$reset();
    },
    clearRepeatPasswordField() {
      this.$v.repeatUserPassword.$reset();
    },
    clearPhoneField() {
      this.$v.userPhone.$reset();
    },
    inputFullNameHandler(fullName) {
      this.userFullName = fullName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    goBack() {
      this.$router.push({ name: 'Signup' });
    },
    async submitHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loadingSubmitBtn = true;
      this.serverError = await this.$store.dispatch(
        'signupIndividualPerson',
        this.userInfo
      );
      this.loadingSubmitBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-individual .signup-overlay:before
  @media screen and (max-width: 600px)
    height: calc(100% - 212px)
</style>
