var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-individual"},[_c('SignupOverlay',[_c('Heading',{attrs:{"level":"4"}},[_vm._v(" "+_vm._s(_vm.$t('signup.individual.title'))+" ")]),_c('div',{staticClass:"signup-individual-form"},[_c('SignupForm',[_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.individual.fullname'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"name","name":"name","required":"","type":"text","error":(_vm.$v.userFullName.$dirty && !_vm.$v.userFullName.required) ||
              (_vm.$v.userFullName.$dirty && _vm.$v.userFullName.$invalid),"error-txt":_vm.userFullNameErrorText},on:{"isFocus":_vm.clearFullNameField,"blur":function($event){return _vm.$v.userFullName.$touch()},"input":_vm.inputFullNameHandler},model:{value:(_vm.userFullName),callback:function ($$v) {_vm.userFullName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userFullName"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.individual.email'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"email","type":"email","name":"email","required":"","error":(_vm.$v.userEmail.$dirty && !_vm.$v.userEmail.required) ||
              (_vm.$v.userEmail.$dirty && _vm.$v.userEmail.$invalid) ||
              !!_vm.serverError,"error-txt":_vm.userEmailErrorText},on:{"isFocus":_vm.clearEmailField,"blur":function($event){return _vm.$v.userEmail.$touch()}},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.individual.password'))+" ")]),_c('TextField',{attrs:{"id":"password","type":_vm.passwordFieldType,"name":"password","required":"","error":(_vm.$v.userPassword.$dirty && !_vm.$v.userPassword.required) ||
              (_vm.$v.userPassword.$dirty && _vm.$v.userPassword.$invalid),"error-txt":_vm.userPasswordErrorText,"is-exist-password-switcher":"","is-show-password":_vm.isShowPassword},on:{"toggle-password":function($event){_vm.isShowPassword = !_vm.isShowPassword},"isFocus":_vm.clearPasswordField,"blur":function($event){return _vm.$v.userPassword.$touch()}},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userPassword"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"repeat-password"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.individual.repeat_password'))+" ")]),_c('TextField',{attrs:{"id":"repeat-password","type":_vm.repeatPasswordFieldType,"name":"repeat-password","required":"","error":(_vm.$v.repeatUserPassword.$dirty &&
                !_vm.$v.repeatUserPassword.required) ||
              (_vm.$v.repeatUserPassword.$dirty && _vm.$v.repeatUserPassword.$invalid),"error-txt":_vm.repeatUserPasswordErrorText,"is-exist-password-switcher":"","is-show-password":_vm.isShowRepeatPassword},on:{"toggle-password":function($event){_vm.isShowRepeatPassword = !_vm.isShowRepeatPassword},"isFocus":_vm.clearRepeatPasswordField,"blur":function($event){return _vm.$v.repeatUserPassword.$touch()}},model:{value:(_vm.repeatUserPassword),callback:function ($$v) {_vm.repeatUserPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"repeatUserPassword"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.individual.phone'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"phone","type":"tel","name":"phone","required":"","placeholder":"+ __ ___ ___ __ __","error":(_vm.$v.userPhone.$dirty && !_vm.$v.userPhone.required) ||
              (_vm.$v.userPhone.$dirty && _vm.$v.userPhone.$invalid),"error-txt":_vm.userPhoneErrorText},on:{"isFocus":_vm.clearPhoneField,"blur":function($event){return _vm.$v.userPhone.$touch()}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userPhone"}})],1),_c('div',{staticClass:"signup-form__group-item checkbox"},[_c('CheckboxField',{attrs:{"error":_vm.$v.agreement.$dirty && !_vm.$v.agreement.required,"error-txt":_vm.agreementErrorText},model:{value:(_vm.$v.agreement.$model),callback:function ($$v) {_vm.$set(_vm.$v.agreement, "$model", $$v)},expression:"$v.agreement.$model"}})],1)])],1),_c('SignupControlButtons',{attrs:{"back-button-text":_vm.$t('signup.button.back'),"go-button-text":_vm.$t('signup.button.registration'),"loading-go-btn":_vm.loadingSubmitBtn},on:{"go-back":_vm.goBack,"go-on":_vm.submitHandler}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }