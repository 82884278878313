<template>
  <div class="checkbox-input">
    <input
      id="agreement"
      type="checkbox"
      name="agreement"
      class="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    />
    <label class="label" for="agreement">
      {{ $t('signup.labels.individual.agreement') }}
    </label>
    <InputError v-if="error" :error-txt="errorTxt" is-show-icon />
  </div>
</template>

<script>
import InputError from '@/elements/Errors/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
    isShowIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.checkbox-input
  display: flex
  position: relative

  .error-message
    bottom: -26px

.checkbox
  position: absolute
  opacity: 0
  top: 0
  left: 0

.checkbox + .label
  position: relative
  display: flex
  align-items: center
  padding-left: 48px
  user-select: none
  font-size: 18px
  line-height: 21px
  color: var(--primary-color-dark-blue)
  cursor: pointer

  @media screen and (max-width: 600px)
    font-size: 16px
    line-height: 19px

.checkbox + .label:before
  position: absolute
  content: ''
  width: 32px
  height: 32px
  left: 0
  border: 1px solid var(--primary-color-dark-grey-1)
  border-radius: 2px
  background-color: transparent

.checkbox:hover + .label:before
  border: 1px solid var(--btn-color-blue)

.checkbox:checked + .label:before
  background-color: var(--btn-color-blue)
  border: 1px solid var(--btn-color-blue)
  background-image: url('/images/checkbox.svg')
  background-position: center
</style>
