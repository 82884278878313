<template>
  <h4 class="title" :class="cssClasses">
    <slot />
  </h4>
</template>

<script>
export default {
  props: {
    level: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssClasses() {
      return {
        'title--1': this.level === '1',
        'title--3': this.level === '3',
        'title--4': this.level === '4',
        'title--5': this.level === '5',
      };
    },
  },
};
</script>

<style lang="sass">
.title
  font-weight: 600
  color: var(--primary-color-dark-blue)

.title--1
  font-size: 64px
  line-height: 76px
  margin-bottom: 48px

.title--3
  font-size: 48px
  font-weight: bold
  line-height: 56px
  margin: 40px 0 32px

  @media screen and (max-width: 1199px)
    font-size: 40px
    line-height: 48px

.title--4
  font-size: 40px
  line-height: 48px
  margin: 40px 0 32px

  @media screen and (max-width: 1199px)
    font-size: 32px
    line-height: 40px
    margin: 24px 0
  @media screen and (max-width: 710px)
    font-size: 24px
    line-height: 32px

.title--5
  font-size: 32px
  line-height: 32px
  font-weight: 700
  margin: 0 0 42px
  @media screen and (max-width: 600px)
    margin-bottom: 30px
    font-size: 24px
</style>
