<template>
  <div class="signup-overlay">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.signup-overlay
  position: relative
  padding-top: 40px
  padding-bottom: 100px
  z-index: 1

  @media screen and (max-width: 600px)
    padding-top: 30px
    padding-bottom: 48px

.signup-overlay:before
  position: absolute
  content: ''
  top: 0
  left: calc(-50vw + 50%)
  width: 100vw
  height: calc(100% - 212px)
  background-color: var(--primary-color-white)
  box-shadow: var(--primary-border-shadow-1)
  z-index: -1

  @media screen and (max-width: 1199px)
    height: calc(100% - 204px)

  @media screen and (max-width: 600px)
    height: calc(100% - 124px)
</style>
