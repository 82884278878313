<template>
  <div class="signup-control-buttons">
    <ButtonBase
      color="white"
      size="md-bg"
      :disabled="isDisabledBackBtn"
      @click.native="$emit('go-back')"
    >
      <template v-if="!loadingBackBtn">
        {{ backButtonText }}
      </template>
      <Loader v-else color="blue" />
    </ButtonBase>
    <ButtonBase
      color="blue"
      size="md-bg"
      :disabled="isDisabledGoBtn"
      @click.native="$emit('go-on')"
    >
      <template v-if="!loadingGoBtn">
        {{ goButtonText }}
      </template>
      <Loader v-else color="white" />
    </ButtonBase>
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    ButtonBase,
    Loader,
  },
  props: {
    backButtonText: {
      type: String,
      required: true,
    },
    goButtonText: {
      type: String,
      required: true,
    },
    isDisabledBackBtn: {
      type: Boolean,
      default: false,
    },
    isDisabledGoBtn: {
      type: Boolean,
      default: false,
    },
    loadingBackBtn: {
      type: Boolean,
      default: false,
    },
    loadingGoBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-control-buttons
  display: flex
  justify-content: center
  gap: 32px
  margin-top: 96px

  @media screen and (max-width: 1199px)
    margin-top: 80px

  @media screen and (max-width: 600px)
    flex-direction: column
    align-items: center
    gap: 24px
    margin-top: 64px

  .primary-btn
    @media screen and (max-width: 600px)
      height: 56px
      width: 100%
</style>
